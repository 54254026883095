import React, { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import storage from "../firebase-config";

const useUpload = (): { progress: number, fileUrl: string | null, handleUpload: (file: File) => Promise<string> } => {
  const [progress, setProgress] = useState<number>(0);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const handleUpload = async (file: File): Promise<string> => {
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(percent);
      },
      (err) => {
        console.log("Error uploading file:", err);
        setProgress(0);
      },
      async () => {
        try {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
          setFileUrl(downloadUrl);
          setProgress(100);
        } catch (err) {
          console.log("Error getting download URL:", err);
          setProgress(0);
        }
      }
    );

    return fileUrl + ""
  };

  return { progress, fileUrl, handleUpload };
};

export default useUpload;