import React, { useState, useEffect, useContext } from "react";
import { ITour } from "../../hooks/useFetchTours";
import Calendar from "../Calendar/Calendar";
import { Button, Flex } from "@chakra-ui/react";
import ReservationModal from "../ReservationModal/ReservationModal";
import UserService from "../../services/UserService";
import { Context } from "../..";
import { Link } from "react-router-dom";
import { routeNames } from "../../router";
import { IDateInterval as IDateIntervalWithDates } from "../Calendar/Calendar";
import Notification from "../Notification/Notification";
import { observer } from "mobx-react-lite";
import { readableDateFromDate } from "../../utils/readableDateTime";

interface ProductInfoProps {
  tour: ITour;
}

const ProductInfo: React.FC<ProductInfoProps> = ({ tour }) => {
  const { store } = useContext(Context);
  const [totalDays, setTotalDays] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  useEffect(() => {
    if (totalDays && tour.price) {
      setTotalPrice(totalDays * tour.price);
    }
  }, [totalDays, tour.price]);

  const [excludedDateIntervals, setExcludedDateIntervals] = useState<
    IDateIntervalWithDates[]
  >([]);

  const [isBookedNotification, setIsBookedNotification] =
    useState<boolean>(false);

  useEffect(() => {
    if (tour?.userTours) {
      const dates: IDateIntervalWithDates[] = [];
      tour.userTours.forEach((userTour) =>
        userTour.excludedDateIntervals.forEach((dateInterval) => {
          const start = new Date(dateInterval.start);
          const end = new Date(dateInterval.end);
          dates.push({
            start,
            end,
          });
        })
      );
      dates.forEach((dateObj) => {
        dateObj.start.setUTCHours(0, 0, 0, 0); // Устанавливаем часовой пояс на GMT+0
        dateObj.end.setUTCHours(0, 0, 0, 0); // Устанавливаем часовой пояс на GMT+0
      });
      setExcludedDateIntervals(dates);
    }
  }, [tour?.userTours]);

  const currentDate = new Date();
  const gmtDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    0,
    0,
    0
  );
  const [startDateInfo, setStartDateInfo] = useState<Date>(gmtDate);
  const [endDateInfo, setEndDateInfo] = useState<Date>(gmtDate);

  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleConfirmReservation = (phone: string, email: string) => {
    UserService.bookTour({
      tourId: tour._id,
      userId: store.user._id,
      excludedDateIntervals: [{ start: startDateInfo, end: endDateInfo }],
      price: totalPrice,
      phone,
      email,
    }).then((resp) => {
      setIsBookedNotification(true);
      console.log(resp, "response after booking");
    });
  };

  return (
    <div className="product__info">
      {isBookedNotification && (
        <Notification
          onClose={() => setIsBookedNotification(false)}
          message={`Жилье успешно забронировано на даты ${readableDateFromDate(
            startDateInfo
          )} - ${readableDateFromDate(endDateInfo)}`}
        />
      )}
      <div className="product__info-top">
        <h3 className="product__title">{tour.title}</h3>
        {store.user.role === "admin" && (
          <Link to={`/product/edit/${tour._id}`}>
            <Button
              colorScheme="linkedin"
              position="absolute"
              top={15}
              right="20px"
            >
              Изменить
            </Button>
          </Link>
        )}
      </div>
      <div className="product__info-middle">
        <div className="product__block">
          <div className="product__price">
            <span className="product__newPrice">
              {Math.round((tour.price - 0.01) * 100) / 100}$
            </span>
            <span className="product__oldPrice">
              {Math.round((tour.price * 1.8 - 0.01) * 100) / 100}$
            </span>
            <p className="product__price-label">Per day</p>
          </div>

          <p className="product__location">
            {tour.city}, {tour.country}
          </p>
        </div>

        <p className="product__description">{tour.description}</p>

        <Calendar
          setTotalDays={setTotalDays}
          setStartDateInfo={setStartDateInfo}
          setEndDateInfo={setEndDateInfo}
          excludedDateIntervals={excludedDateIntervals}
        />
      </div>
      <div className="product__info-bottom">
        <Flex gap={4}>
          <p className="product__total-label">
            Summary {totalDays} days * {tour.price}$ =
          </p>
          <p className="product__total-price">{totalPrice.toFixed(2)}$</p>
        </Flex>
        {/* <PayPal /> */}
      </div>
      {store.isAuth ? (
        <Button p={4} colorScheme="oasis" onClick={() => setIsOpenModal(true)}>
          Забронировать
        </Button>
      ) : (
        <Button p={4} colorScheme="gray">
          <Link
            to={routeNames.LOGIN}
            style={{ width: "100%", wordBreak: "break-word" }}
          >
            Залогиньтесь чтобы бронировать
          </Link>
        </Button>
      )}
      <ReservationModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onConfirm={handleConfirmReservation}
        startDateInfo={startDateInfo}
        endDateInfo={endDateInfo}
        totalPrice={totalPrice}
        proposedEmail={store.user.email}
        proposedPhone={store.user.phone}
      />
    </div>
  );
};

export default observer(ProductInfo);
