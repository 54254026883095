import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  Select,
  FormControl,
  FormLabel,
  Button,
  Flex,
} from "@chakra-ui/react";
import { IUserTour } from "../../../models/IUserTour";
import UserService from "../../../services/UserService";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import ModalDeleteButton from "../../ModalDeleteButton/ModalDeleteButton";
import Notification from "../../Notification/Notification";
import { readableDateFromString } from "../../../utils/readableDateTime";
import { Link } from "react-router-dom";

interface UserTourCardProps {
  tourData: IUserTour;
}

type statusType =
  | "sentRequest"
  | "confirmed"
  | "living"
  | "ended"
  | "error"
  | "noStatus";
const statusOptions: statusType[] = [
  "sentRequest",
  "confirmed",
  "living",
  "ended",
  "error",
  "noStatus",
];

const TourStatusCard: React.FC<UserTourCardProps> = ({ tourData }) => {
  const [status, setStatus] = useState<statusType>("noStatus");
  const [userTour, setUserTour] = useState<IUserTour | undefined>();

  const [isUserTourDeleted, setIsUserTourDeleted] = useState<boolean>(false);
  useEffect(() => {
    setStatus(tourData.status);
    setUserTour(tourData);
  }, []);

  if (!userTour) {
    return <LoadingSpinner />;
  }

  const handleChangeStatus = () => {
    if (status === "noStatus") return;
    UserService.changeTourStatus(userTour._id, status).then((resp) => {
      console.log(resp, "changed");
      setUserTour(resp.data);
    });
  };

  const deleteUserTour = () => {
    UserService.deleteUserTour(userTour._id).then((resp) => {
      console.log(resp, "resp");
      setIsUserTourDeleted(true);
    });
  };
  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      bg="white"
      maxW="300px"
      w="300px"
      mx="auto"
    >
      {isUserTourDeleted && (
        <Notification
          onClose={() => setIsUserTourDeleted(false)}
          message="Бронирование было успешно удалено! Перезагрузите страницу чтобы увидеть изменения"
        />
      )}
      <Box mb={4}>
        <Image
          src={
            userTour.tourPreview || "https://lasd.lv/public/assets/no-image.png"
          }
          alt={userTour.tourName}
          maxH="200px"
          objectFit="cover"
        />
      </Box>
      <Link to={`/product/${userTour.tourId}`}>
        <Text
          _hover={{
            textDecoration: "underline",
          }}
          _focus={{
            outline: "none",
            textDecoration: "underline",
          }}
          fontWeight="bold"
        >
          {userTour.tourName}
        </Text>
      </Link>
      <FormControl mt={2}>
        <FormLabel>Status:</FormLabel>
        <Select
          value={status}
          onChange={(e) => setStatus(e.target.value as statusType)}
        >
          {statusOptions.map((status: statusType) =>
            status !== "noStatus" ? (
              <option key={status} value={status}>
                {status}
              </option>
            ) : (
              <option key={status} value={status} disabled>
                {status}
              </option>
            )
          )}
        </Select>
      </FormControl>
      <Text>
        Заезд: {readableDateFromString(userTour.excludedDateIntervals[0].start)}
      </Text>
      <Text>
        Выезд: {readableDateFromString(userTour.excludedDateIntervals[0].end)}
      </Text>

      <Link to={`/user/${userTour.userId}`}>
        <Box
          as="span"
          display="inline-block"
          textDecoration="none"
          color="blue.500"
          _hover={{
            textDecoration: "underline",
          }}
          _focus={{
            outline: "none",
            textDecoration: "underline",
          }}
          my={4}
        >
          <Flex gap={2} wrap="wrap" my={2}>
            <Text>Email:</Text>
            <Text fontWeight="semibold" wordBreak="break-all">
              {userTour.email}
            </Text>
          </Flex>
          <Flex gap={2} wrap="wrap" my={2}>
            <Text>Телефон:</Text>
            <Text fontWeight="semibold" wordBreak="break-all">
              {userTour.phone}
            </Text>
          </Flex>
        </Box>
      </Link>

      <Flex justifyContent="space-between">
        {status === userTour.status ? (
          <Button isDisabled>Update</Button>
        ) : (
          <Button onClick={handleChangeStatus} colorScheme="teal">
            Обновить
          </Button>
        )}
        <ModalDeleteButton
          handleDelete={() => {
            deleteUserTour();
          }}
        />
      </Flex>
    </Box>
  );
};

export default TourStatusCard;
