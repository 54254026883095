import { useEffect, useState } from "react";
import { ITour } from "./useFetchTours";
import { IUser } from "../models/IUser";





const useFetchOneUser = (id: string): [user: IUser | null, isLoading: boolean, error: Error | null] => {
  const [user, setUser] = useState<IUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchUser = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, { headers: { "Authorization": `Bearer ${localStorage.getItem('token')}` } });
        if (response.ok) {
          const responseUser = await response.json();
          setUser(responseUser);
        } else {
          throw new Error("Error while trying to load data from the server");
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, []);

  return [user, isLoading, error];
};

export default useFetchOneUser;
