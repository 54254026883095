import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "../../router";
import { Context } from "../..";

import PhoneInput from "react-phone-number-input";
import { observer } from "mobx-react-lite";

const Register = () => {
  const { store } = useContext(Context);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState<any>();
  const [password, setPassword] = useState("");
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(email, phone, password);
    store
      .register(email, phone, password)
      .then(() => navigate(routeNames.HOME));
  };

  return (
    <Box maxW="md" mx="auto" py="14" w="90%">
      <form onSubmit={handleSubmit}>
        <Stack spacing="4">
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl>
            <FormLabel>Телефон</FormLabel>
            <PhoneInput
              international
              placeholder="Enter phone number"
              defaultCountry="IL"
              onChange={setPhone}
              // inputComponent={}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button type="submit" colorScheme="blue" isDisabled={false}>
            Зарегистрироваться
          </Button>
          <Text textAlign="center" fontSize={16}>
            Уже есть аккаунт?{" "}
            <Text
              as="span"
              color="blue.500"
              fontWeight="medium"
              textDecoration="underline"
            >
              <Link to={routeNames.LOGIN}>Войдите</Link>
            </Text>
          </Text>
        </Stack>
      </form>
    </Box>
  );
};

export default observer(Register);
