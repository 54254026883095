import React from "react";
import Container from "../Container/Container";

import "./picture.scss";

const Picture = () => {
  return (
    <div className="picture">
      <Container>
        <div className="picture__wrapper">
          <div className="picture__wrapper-content tracking-in-expand-fwd-bottom">
            <h1 className="picture__title">
              Идеальное жилье для вашего отдыха!
            </h1>
            <h2 className="picture__subtitle">
              Откройте мир идеальной посуточной аренды: Лучшее соотношение цены
              и качества для каждой поездки!
            </h2>
          </div>
          <div className="picture__wrapper-blur" />
        </div>
      </Container>
    </div>
  );
};

export default Picture;
