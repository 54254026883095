import React from "react";
import Container from "../../conmponents/Container/Container";

import "./product.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import useFetchOneTour from "../../hooks/useFetchOneTour";
import { useParams } from "react-router";
import ProductInfo from "../../conmponents/ProductInfo/ProductInfo";
import Review from "../../conmponents/Reviews/Review/Review";
import Loading from "../../conmponents/Loading/Loading";
import ReviewForm from "../../conmponents/Reviews/ReviewForm/ReviewForm";
import Map from "../../conmponents/Map/Map";
import { Box } from "@chakra-ui/react";

const Product = () => {
  const { id } = useParams<{ id: string }>();
  const [tour, isLoading, error] = useFetchOneTour(`${id}`);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      paritialVisibilityGutter: 50,
    },
  };

  if (isLoading) {
    return <Loading message="Apartment" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!tour) {
    return <div>Tour has not been found</div>;
  }
  return (
    <div className="product">
      <Container>
        <div className="product__wrapper">
          {tour?.images ? (
            <Carousel
              swipeable={true}
              draggable={false}
              showDots={true}
              responsive={responsive}
              autoPlaySpeed={1000}
              keyBoardControl={true}
              containerClass="carousel-container"
              // removeArrowOnDeviceType={["tablet", "mobile"]}
              deviceType="desctop"
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              // partialVisible
              className="product__carousel"
            >
              {tour.images.map((image) => (
                <img
                  key={image}
                  src={image}
                  alt={image}
                  className="product__img"
                />
              ))}
            </Carousel>
          ) : (
            <></>
          )}
          {tour && <ProductInfo tour={tour} />}
        </div>
        <Box py={12} />
        <Map position={[tour.yPosition, tour.xPosition]} />
        <div className="product__reviews">
          {tour?.reviews.map((review) => (
            <Review key={review.text} review={review} />
          ))}
        </div>
        {/* <AddReview tourId={tour._id} /> */}
        <ReviewForm
          // onSubmit={() => {
          //   console.log("on Submin");
          // }}
          tourId={tour._id}
        />
      </Container>
    </div>
  );
};

export default Product;
