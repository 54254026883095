

export const readableDateFromString = (dateString: string) => {
  const date = new Date(dateString);

  // Получаем день, месяц и год
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы начинаются с 0, поэтому прибавляем 1
  const year = date.getFullYear();

  // Получаем часы и минуты
  // const hours = date.getHours();
  // const minutes = date.getMinutes();

  // Формируем читабельную дату
  const readableDate = `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
  // const readableTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;

  // Комбинируем дату и время
  const result = `${readableDate}`;
  // const result = `${readableDate} ${readableTime}`;

  return result
}

export const readableDateFromDate = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1; // Месяцы начинаются с 0, поэтому прибавляем 1
  const year = date.getFullYear();

  // Получаем часы и минуты
  const hours = date.getHours();
  // const minutes = date.getMinutes();

  // Формируем читабельную дату
  const readableDate = `${day < 10 ? "0" + day : day}.${month < 10 ? "0" + month : month}.${year}`;
  // const readableTime = `${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}`;

  // Комбинируем дату и время
  const readableDateTime = `${readableDate}`;

  return readableDateTime;
}