import React from "react";
import { IUser } from "../../../models/IUser";
import { Avatar, Badge, Box, Flex, Text } from "@chakra-ui/react";
import UserTourCard from "../UserTourCard/UserTourCard";
import { IUserTour } from "../../../models/IUserTour";

interface ProfileProductsProps {
  user: IUser;
}

const ProfileProducts: React.FC<ProfileProductsProps> = ({ user }) => {
  return (
    <Box maxW="1040" mx="auto">
      <Box
        p={4}
        borderWidth="1px"
        borderRadius="md"
        boxShadow="md"
        bg="white"
        maxW="400px"
        mx="auto"
      >
        <Flex align="center" mb={4}>
          <Avatar
            src={user.profilePhoto}
            name={user.firstname}
            size="md"
            mr={2}
          />
          <Text fontWeight="bold" fontSize={20}>
            {user.firstname} {user.lastname}
          </Text>
        </Flex>
        <Flex gap={2} wrap="wrap" my={2}>
          <Text>Email:</Text>
          <Text fontWeight="semibold" wordBreak="break-all">
            {user.email}
          </Text>
        </Flex>
        <Flex gap={2} wrap="wrap" my={2}>
          <Text>Телефон:</Text>
          <Text fontWeight="semibold" wordBreak="break-all">
            {user.phone}
          </Text>
        </Flex>
        <Badge
          variant="solid"
          colorScheme={user.role === "admin" ? "red" : "green"}
          mt={4}
        >
          {user.role}
        </Badge>
      </Box>
      <Flex wrap="wrap" gap={8} my={16}>
        {user.userTours?.map((userTour: IUserTour) => (
          <UserTourCard key={userTour._id} userTour={userTour} />
        ))}
      </Flex>
    </Box>
  );
};

export default ProfileProducts;
