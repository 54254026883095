import React, { useState, useContext } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "../../router";
import { Context } from "../..";
import { observer } from "mobx-react-lite";

const Login = () => {
  const { store } = useContext(Context);

  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoadingLogin, setIsLoadingLogin] = useState(false);
  const [isErrorLogin, setIsErrorLogin] = useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsErrorLogin(false);
    setIsLoadingLogin(true);
    store
      .login(email, password)
      .then((respCode) => {
        console.log(respCode, "resp onse");
        if (respCode && respCode >= 200 && respCode < 300) {
          navigate(routeNames.HOME);
        } else {
          setIsErrorLogin(true);
        }
      })
      .finally(() => setIsLoadingLogin(false));
  };

  return (
    <Box maxW="md" mx="auto" py="14" w="90%">
      <form onSubmit={handleSubmit}>
        <Stack spacing="4">
          <FormControl id="email" isRequired>
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <FormControl id="password" isRequired>
            <FormLabel>Password</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            colorScheme="blue"
            isDisabled={false}
            isLoading={isLoadingLogin}
          >
            Войти
          </Button>
          {isErrorLogin && (
            <Text color="#EE002E" mb={4} fontWeight={500} fontSize={14}>
              Возникла ошибка вовремя попытки логина! Проверьте верны ли
              ваши данные, либо Зарегистрируйтесь если ещё не имеете аккаунта
            </Text>
          )}
          <Text textAlign="center" fontSize={16}>
            Ещё нет аккаунта?{" "}
            <Text
              as="span"
              color="blue.500"
              fontWeight="medium"
              textDecoration="underline"
            >
              <Link to={routeNames.REGISTER}>Зарегистрируйтесь</Link>
            </Text>
          </Text>
        </Stack>
      </form>
    </Box>
  );
};

export default observer(Login);
