import React, { useRef } from "react";
import { Box, Grid, Text } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faMoneyBill,
  faUserGear,
  faShippingFast,
} from "@fortawesome/free-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
// import { useIsInViewport } from "../../hooks/useIsInViewport";
interface Advantage {
  title: string;
  description: string;
  icon: IconProp;
  ref: React.MutableRefObject<any>;
}

const Advantages: React.FC = () => {
  // const ref1 = useRef(null);

  // const isInViewport1 = useIsInViewport(ref1);
  // console.log("isInViewport1: ", isInViewport1);

  const advantages: Advantage[] = [
    {
      title: "Большой выбор!",
      description: "Прекрасные варианты жилья для вашего отдыха!",
      icon: faStar,
      ref: useRef(null),
    },
    {
      title: "Превосходный сервис!",
      description: "Вы можете обращаться по любым вопросам 24/7",
      icon: faUserGear,
      ref: useRef(null),
    },
    {
      title: "Услуга трансфера!",
      description:
        "Вы можете заказать услугу трансфера из аэропорта или в аэропорт в удобное для вас время!",
      icon: faShippingFast,
      ref: useRef(null),
    },
    {
      title: "Низкие цены!",
      description: "Лучшее соотношение цена-качество в Израиле!",
      icon: faMoneyBill,
      ref: useRef(null),
    },
  ];

  return (
    <Box py="14" bg="white">
      <Box maxW="1040" mx="auto">
        <Grid
          templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }}
          gap="16px"
        >
          {advantages.map((advantage, index) => (
            <Box
              key={index}
              textAlign="center"
              borderWidth="1px"
              borderRadius="lg"
              p="4"
              boxShadow="md"
            >
              <Box fontSize="3xl" color="green.500" mb="2">
                <FontAwesomeIcon icon={advantage.icon} />
              </Box>
              <Text fontWeight="bold" fontSize="xl" mb="2">
                {advantage.title}
              </Text>
              <Text fontSize="16">{advantage.description}</Text>
            </Box>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Advantages;
