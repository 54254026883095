import React from "react";
import "./error.scss";
import Container from "../Container/Container";

interface ErrorProps {
  errorMessage: string;
}

const Error: React.FC<ErrorProps> = ({ errorMessage }) => {
  return (
    <div className="error">
      <Container>
        <div>Error: {errorMessage}</div>
      </Container>
    </div>
  );
};

export default Error;
