import { useState, useEffect, useContext } from "react";
import { Box } from "@chakra-ui/react";
import { observer } from "mobx-react-lite";
import TabsComponent, { ITabData } from "../TabsComponent/TabsComponent";
import ProfileInfo from "./ProfileInfo/ProfileInfo";
import ProfileProducts from "./ProfileProducts/ProfileProducts";
import { Context } from "../..";
import useFetchOneUser from "../../hooks/useFetchOneUser";
import Loading from "../Loading/Loading";
import ProfileManage from "./ProfileManage/ProfileManage";
import UserService from "../../services/UserService";
import TourForm from "./TourForm/TourForm";
import { ICreateTour } from "../../hooks/useFetchTours";

const Profile = () => {
  const { store } = useContext(Context);
  const [user, isLoading, error] = useFetchOneUser(`${store.user._id}`);

  const createTour = (tourData: ICreateTour) => {
    return UserService.createTour(tourData);
  };

  if (isLoading) {
    return <Loading message="User Info" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!user) {
    return <div>User has not been found</div>;
  }

  const tabsData: ITabData[] =
    store.user.role === "admin"
      ? [
          { label: "Мое жилье", component: <ProfileProducts user={user} /> },
          {
            label: "Данные профиля",
            component: (
              <ProfileInfo user={user} updateUser={store.updateUser} />
            ),
          },
          {
            label: "Статус жилья",
            component: <ProfileManage />,
          },
          {
            label: "Добавить новое жилье",
            component: (
              <TourForm
                createTour={(tourData: ICreateTour) => createTour(tourData)}
              />
            ),
          },
        ]
      : [
          { label: "Мое жилье", component: <ProfileProducts user={user} /> },
          {
            label: "Данные профиля",
            component: (
              <ProfileInfo user={user} updateUser={store.updateUser} />
            ),
          },
        ];

  return (
    <Box maxW={1040} mx="auto">
      <TabsComponent tabsData={tabsData} />
    </Box>
  );
};

export default observer(Profile);
