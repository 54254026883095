import React from "react";
import ProductItem from "./ProductItem";
import Container from "../Container/Container";

import "./productsList.scss";
import useToursLoader from "../../hooks/useFetchTours";
import Loading from "../Loading/Loading";
import Error from "../Error/Error";

const ProductsList = () => {
  const [tours, isLoading, error] = useToursLoader();

  if (isLoading) {
    return <Loading message="Products" />;
  }

  if (error) {
    return <Error errorMessage={error.message} />;
  }
  return (
    <div className="products">
      <Container>
        <h2>Наши лучшие апартаменты</h2>
        <div className="products__wrapper">
          {tours?.map((tour) => (
            <ProductItem key={tour._id} tour={tour} />
          ))}
        </div>
      </Container>
    </div>
  );
};

export default ProductsList;
