import { useEffect, useState } from "react";
import { ITour } from "./useFetchTours";

const useFetchOneTour = (
  id: string
): [tours: ITour | null, isLoading: boolean, error: Error | null] => {
  const [tour, setTours] = useState<ITour | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchTours = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/tour/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response.ok) {
          const responseTours = await response.json();
          setTours(responseTours);
        } else {
          throw new Error("Error while trying to load data from the server");
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTours();
  }, []);

  return [tour, isLoading, error];
};

export default useFetchOneTour;
