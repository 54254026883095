import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";

import "./calendar.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Text } from "@chakra-ui/react";

interface CalendarProps {
  setTotalDays: (time: number) => void;
  setStartDateInfo: (date: Date) => void;
  setEndDateInfo: (date: Date) => void;
  excludedDateIntervals: IDateInterval[];
}

export interface IDateInterval {
  start: Date;
  end: Date;
}

const Calendar: React.FC<CalendarProps> = ({
  setTotalDays,
  setStartDateInfo,
  setEndDateInfo,
  excludedDateIntervals,
}) => {
  const evaluateDate = (date: Date | null, type: "start" | "end") => {
    if (date !== null) {
      if (type === "start" && date.getTime() - endDate.getTime() > 0) {
        setStartDate(date);
        setEndDate(date);
        setTotalDays(1);
      } else if (type === "start") {
        setStartDate(date);

        if (endDate && endDate.getTime()) {
          setTotalDays(
            Math.ceil(
              (endDate.getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
            )
          );
        }

        shiftEndDate(date, endDate, excludedDateIntervals);
      } else {
        setEndDate(date);

        if (startDate && startDate.getTime()) {
          setTotalDays(
            Math.ceil(
              (date.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24)
            )
          );
        }
      }
    }
  };

  const evaluateMaxEndDate = (
    startDate: Date,
    excludedDateIntervals: IDateInterval[]
  ): null | Date => {
    let minExcludeDateStart: null | Date = null;
    excludedDateIntervals.forEach((excludeInterval) => {
      if (
        excludeInterval.start.getTime() > startDate.getTime() &&
        (minExcludeDateStart === null ||
          minExcludeDateStart.getTime() > excludeInterval.start.getTime())
      ) {
        minExcludeDateStart = excludeInterval.start;
      }
    });
    return minExcludeDateStart;
  };

  const shiftEndDate = (
    startDate: Date,
    endDate: Date,
    excludedDateIntervals: IDateInterval[]
  ): void => {
    console.log("is in shift");
    excludedDateIntervals.forEach((excludeInterval) => {
      if (
        startDate.getTime() < excludeInterval.start.getTime() &&
        endDate.getTime() > excludeInterval.end.getTime()
      ) {
        setEndDate(startDate);
        setTotalDays(1);
      }
    });
  };

  const getFirstIncludedDate = (
    firstDate: Date,
    excludedDateIntervals: IDateInterval[]
  ): Date => {
    let resultDate: Date = firstDate;

    excludedDateIntervals.forEach((excludedInterval) => {
      if (isDateInInterval(firstDate, excludedInterval)) {
        const nextDay = new Date(excludedInterval.end);
        nextDay.setDate(excludedInterval.end.getDate() + 1);
        resultDate = nextDay;
        resultDate = getFirstIncludedDate(nextDay, excludedDateIntervals);
      }
    });
    return resultDate;
  };

  const isDateInInterval = (date: Date, dateInterval: IDateInterval) => {
    if (
      date.getTime() >= dateInterval.start.getTime() &&
      date.getTime() <= dateInterval.end.getTime()
    ) {
      return true;
    }
    return false;
  };

  const currentDate = new Date();
  const gmtDate = new Date(
    currentDate.getUTCFullYear(),
    currentDate.getUTCMonth(),
    currentDate.getUTCDate(),
    0,
    0,
    0
  );

  const setStartDate = (date: Date) => {
    setStartDateData(date);
    setStartDateInfo(date);
  };

  const setEndDate = (date: Date) => {
    setEndDateDate(date);
    setEndDateInfo(date);
  };
  // console.log(gmtDate, "gmtDate");
  useEffect(() => {
    if (excludedDateIntervals.length > 0) {
      console.log(excludedDateIntervals, "excludedDateIntervals on ");
      setStartDateInfo(getFirstIncludedDate(gmtDate, excludedDateIntervals));
      setEndDateInfo(startDate);
    }
  }, [excludedDateIntervals]);
  const [startDate, setStartDateData] = useState<Date>(
    getFirstIncludedDate(gmtDate, excludedDateIntervals)
  );
  const [endDate, setEndDateDate] = useState<Date>(startDate);

  return (
    <div className="calendar">
      <Text mb={4}>Выберети дату заезда и дату выезда</Text>
      {/* <h4 className="calendar__title">Выберети дату заезда и дату выезда</h4> */}
      <div className="calendar__content">
        <DatePicker
          selected={startDate}
          onChange={(date) => evaluateDate(date, "start")}
          selectsStart
          minDate={new Date()}
          startDate={startDate}
          endDate={endDate}
          excludeDateIntervals={excludedDateIntervals}
          className="calendar__start"
        />
        <DatePicker
          selected={endDate}
          onChange={(date) => evaluateDate(date, "end")}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          maxDate={evaluateMaxEndDate(startDate, excludedDateIntervals)}
          excludeDateIntervals={excludedDateIntervals}
          className="calendar__end"
        />
      </div>
    </div>
  );
};

export default Calendar;
