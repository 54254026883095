import { Box, Text, Badge, Image } from "@chakra-ui/react";
import { IUserTour } from "../../../models/IUserTour";
import { readableDateFromString } from "../../../utils/readableDateTime";
import { Link } from "react-router-dom";

interface UserTourCardProps {
  userTour: IUserTour;
}

const UserTourCard: React.FC<UserTourCardProps> = ({ userTour }) => {
  return (
    <Box
      p={4}
      borderWidth="1px"
      borderRadius="md"
      boxShadow="md"
      bg="white"
      maxW="300px"
      mx="auto"
    >
      <Box mb={4}>
        <Image
          src={
            userTour.tourPreview
              ? userTour.tourPreview
              : "https://lasd.lv/public/assets/no-image.png"
          }
          alt={userTour.tourName}
          maxH="200px"
          objectFit="cover"
        />
      </Box>
      <Link to={`/product/${userTour.tourId}`}>
        <Text
          _hover={{
            textDecoration: "underline",
          }}
          _focus={{
            outline: "none",
            textDecoration: "underline",
          }}
          fontWeight="bold"
        >
          {userTour.tourName}
        </Text>
      </Link>
      <Text my={2}>{userTour.price}$</Text>
      <Box my={2}>
        <Badge
          colorScheme={getStatusData(userTour.status).color}
          wordBreak="break-word"
          whiteSpace="initial"
          display="inline-block"
          p={1}
        >
          {/* <Text
              width="100%"
              fontSize={14}
            > */}
          {getStatusData(userTour.status).text}
          {/* </Text> */}
        </Badge>
      </Box>
      <Text>
        Заезд: {readableDateFromString(userTour.excludedDateIntervals[0].start)}
      </Text>
      <Text>
        Выезд: {readableDateFromString(userTour.excludedDateIntervals[0].end)}
      </Text>
    </Box>
  );
};

const getStatusData = (status: IUserTour["status"]) => {
  switch (status) {
    case "sentRequest":
      return { color: "gray", text: "Запрос отправлен" };
    case "confirmed":
      return { color: "green", text: "Запрос на проживаение подтвержден" };
    case "living":
      return {
        color: "blue",
        text: "Проживание",
      };
    case "ended":
      return { color: "purple", text: "Завершено" };
    case "error":
      return { color: "red", text: "Ошибка. " };
    default:
      return { color: "gray", text: "Статус не найден" };
  }
};

export default UserTourCard;
