import React, { useContext } from "react";
import Container from "../Container/Container";

import "./header.scss";

import logo from "../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../..";
import { routeNames } from "../../router";
import { observer } from "mobx-react-lite";
// import { useAuth } from "../../hooks/useAuth";

const Header = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const logout = () => {
    store.logout().then(() => navigate(routeNames.LOGIN));
  };
  return (
    <>
      <div className="header">
        <Container>
          <div className="header__wrapper">
            <a href="/" className="header__logo">
              <img src={logo} alt="logo" />
            </a>

            <div className="header__menu">
              {/* <Link to={`product/${_id}`} className="productItem">/ */}
              {store.isAuth ? (
                <>
                  <Link to={`/profile`} className="header__menu-item">
                    Мой профиль
                  </Link>
                  <p className="header__menu-item" onClick={() => logout()}>
                    Выйти
                  </p>
                </>
              ) : (
                <Link to="login" className="header__menu-item">
                  Войти
                </Link>
              )}
            </div>
          </div>
        </Container>
      </div>

      <div className="header-space" />
    </>
  );
};

export default observer(Header);
