import React, { useState, useEffect, useContext } from "react";

import { Routes, Route, Navigate } from "react-router-dom";
import {
  adminRoutes,
  privateRoutes,
  publicRoutes,
  routeNames,
} from "../router";
import { Context } from "..";
import { observer } from "mobx-react-lite";
import LoadingSpinner from "./LoadingSpinner/LoadingSpinner";

const AppRouter = () => {
  const { store } = useContext(Context);
  const [isLoadingAuth, setIsLoadingAuth] = useState(true);

  useEffect(() => {
    setIsLoadingAuth(false);
  }, [store.isLoading]);

  if (isLoadingAuth) {
    return <LoadingSpinner />;
  }

  // console.log(store.isLoading, "isLoading in approuter");

  return (
    <Routes>
      {store.isAuth
        ? store.user.role === "admin"
          ? [...privateRoutes, ...adminRoutes].map((route) => (
              <Route
                key={route.path}
                path={route.path + (!route.exact ? "/*" : "")}
                element={<route.component />}
              />
            ))
          : privateRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path + (!route.exact ? "/*" : "")}
                element={<route.component />}
              />
            ))
        : publicRoutes.map((route) => (
            <Route
              key={route.path}
              path={route.path + (!route.exact ? "/*" : "")}
              element={<route.component />}
            />
          ))}

      <Route
        path="*"
        element={
          <Navigate
            to={store.isAuth ? routeNames.HOME : routeNames.LOGIN}
            replace
          />
        }
      />
    </Routes>
  );
};

export default observer(AppRouter);
