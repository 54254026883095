import React, { useContext, useEffect } from "react";
import AppRouter from "./conmponents/AppRouter";
import { BrowserRouter } from "react-router-dom";
import Header from "./conmponents/Header/Header";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Context } from ".";
import { observer } from "mobx-react-lite";
import LoadingSpinner from "./conmponents/LoadingSpinner/LoadingSpinner";
import ReactGa from "react-ga4";

const TRACKING_ID = "G-H1638M3D3B";
ReactGa.initialize(TRACKING_ID);

const App = () => {
  const { store } = useContext(Context);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      store.checkAuth();
    }
  }, []);

  if (store.isLoading) {
    return <LoadingSpinner />;
  }
  const oasisTheme = extendTheme({
    colors: {
      // oasis: {
      //   50: "#E6E7E9",
      //   100: "#C2C6CD",
      //   200: "#9B9FA9",
      //   300: "#737A87",
      //   400: "#515870",
      //   500: "#364170", // Oasis black
      //   600: "#2B3C60",
      //   700: "#212E50",
      //   800: "#161F40",
      //   900: "#0B0F30",
      // },
      oasis: {
        50: "#E7EAF1",
        100: "#C2C9D8",
        200: "#9DA6C0",
        300: "#7982A8",
        400: "#5C6C94",
        500: "#46507D", // Oasis
        600: "#3A406A",
        700: "#2E3056",
        800: "#222342",
        900: "#17172E",
      },  
    },
  });
  return (
    <ChakraProvider theme={oasisTheme}>
      <div className="App">
        <BrowserRouter>
          <Header />
          <AppRouter />
        </BrowserRouter>
      </div>
    </ChakraProvider>
  );
};

export default observer(App);
