import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useToast,
  Text,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTeletype } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../..";
import UserService from "../../services/UserService";

const EmailForm: React.FC = () => {
  const { store } = useContext(Context);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [loadingEmail, setLoadingEmail] = useState(false);

  const toast = useToast();

  useEffect(() => {
    if (store.user.email) {
      setEmail(store.user.email);
    }
  }, [store.user.email]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setLoadingEmail(true);

    UserService.sendEmailNotification(email, message)
      .then((resp) => {
        console.log(resp, "response");
        toast({
          title: "Письмо отправлено!",
          description:
            "Спасибо за ваше сообщение. Мы свяжемся с вами в ближайшее время.",
          status: "success",
          duration: 5000,
          isClosable: true,          
        });
        setEmail("");
        setMessage("");
      })
      .catch((err) => {
        toast({
          title: "Письмо не было доставлено!",
          description:
            "Произошла ошибка, пожалуйста свяжитесь с нами в ручном режиме",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      })
      .finally(() => {
        setLoadingEmail(false);
      });
  };

  return (
    <Box maxW="740" color="white">
      <Text color="white" fontSize={34} fontWeight={500} my={8}>
        Ещё остались вопросы? Напишите нам
      </Text>
      <form onSubmit={handleSubmit}>
        <FormControl id="email" isRequired>
          <FormLabel>Ваш Email</FormLabel>
          <Input
            type="email"
            placeholder="Введите ваш Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            color="white"
            bg="gray.700"
          />
        </FormControl>
        <FormControl id="message" mt={4} isRequired>
          <FormLabel>Сообщение</FormLabel>
          <Textarea
            placeholder="Введите ваше сообщение..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            color="white"
            bg="gray.700"
          />
        </FormControl>
        <Button
          type="submit"
          mt={4}
          leftIcon={<FontAwesomeIcon icon={faTeletype} fontSize={12} />}
          colorScheme="oasis"
          isLoading={loadingEmail}
        >
          Отправить
        </Button>
      </form>
    </Box>
  );
};

export default EmailForm;
