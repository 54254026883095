import React, { useEffect, useState } from "react";
import { Alert, AlertIcon, CloseButton } from "@chakra-ui/react";

interface NotificationProps {
  message: string;
  onClose: () => void;
}

const Notification: React.FC<NotificationProps> = ({ message, onClose }) => {
  const [show, setShow] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(false);
      onClose();
    }, 3000);

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <>
      {show && (
        <Alert
          status="success"
          variant="solid"
          mb={4}
          borderRadius="md"
          position="fixed"
          w="90%"
          left="5%"
          top="150px"
          zIndex={2000}
        >
          <AlertIcon />
          {message}
          <CloseButton
            onClick={() => {
              setShow(false);
              onClose();
            }}
            ml="auto"
          />
        </Alert>
      )}
    </>
  );
};

export default Notification;
