import { useState, useEffect, useContext, useRef } from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@chakra-ui/react";
import useFetchOneUser from "../../../hooks/useFetchOneUser";
import { observer } from "mobx-react-lite";
import { IUser } from "../../../models/IUser";
import PhoneInput from "react-phone-number-input";
import useUpload from "../../../hooks/useUpload";
import ProgressBar from "../../ProgressBar/ProgressBar";
import { IUpdateUser } from "../../../services/interfaces/IUpdateUser";

interface ProfileInfoProps {
  user: IUser | null;
  updateUser: (userData: IUpdateUser) => void;
}

const ProfileInfo: React.FC<ProfileInfoProps> = ({ user, updateUser }) => {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<any>();
  const [profilePhoto, setProfilePhoto] = useState<string>("");
  const [profileFile, setProfileFile] = useState<File>();

  const profileRef = useRef<HTMLInputElement>(null);

  const { progress, fileUrl, handleUpload } = useUpload();

  useEffect(() => {
    if (user?.firstname) {
      setFirstname(user?.firstname);
    }
    if (user?.lastname) {
      setLastname(user?.lastname);
    }
    if (user?.email) {
      setEmail(user?.email);
    }
    if (user?.phone) {
      setPhone(user?.phone);
    }
    if (user?.profilePhoto) {
      setProfilePhoto(user?.profilePhoto);
    }
  }, [user]);

  useEffect(() => {
    console.log(
      profilePhoto,
      "profilePhoto and ",
      user?.profilePhoto,
      profilePhoto === user?.profilePhoto,
      profilePhoto === "" && user?.profilePhoto === undefined
    );
    if (
      firstname === user?.firstname &&
      lastname === user.lastname &&
      phone === user.phone &&
      email === user.email &&
      (profilePhoto === user.profilePhoto ||
        (profilePhoto === "" && user?.profilePhoto === undefined))
    ) {
      setIsUpdated(false);
    } else if (isUpdated === false) {
      setIsUpdated(true);
    }
  }, [firstname, lastname, email, phone, profilePhoto]);

  useEffect(() => {
    if (fileUrl) {
      setProfilePhoto(fileUrl);
    }
  }, [fileUrl]);

  const handleProfileImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      handleUpload(file);
    }
  };

  const handleUpdateProfile = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();

    const userData: IUpdateUser = {
      firstname,
      lastname,
      email,
      phone,
      profilePhoto,
    };

    updateUser(userData);
  };

  const [isUpdated, setIsUpdated] = useState(false);

  return (
    <Box p={4} maxW={750} mx="auto">
      <Center>
        <Heading as="h2" my={8}>
          Мой профиль
        </Heading>
      </Center>
      <VStack spacing={4} align="start">
        <FormControl id="profileImage">
          <FormLabel>Картинка профиля</FormLabel>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            width="200px"
            border="1px dashed gray"
            borderRadius="md"
            cursor="pointer"
            onClick={() => profileRef?.current?.click()}
            // onClick={}
          >
            {!profilePhoto && <Text>Upload Image</Text>}

            {profilePhoto && (
              <Image
                src={profilePhoto}
                alt="Profile Image"
                maxW="200px"
                maxH="200px"
              />
            )}
          </Box>
          <ProgressBar minValue={0} maxValue={100} currentValue={progress} />

          <Input
            display="none"
            type="file"
            accept="image/*"
            ref={profileRef}
            onChange={handleProfileImageChange}
          />
        </FormControl>
        <FormControl id="firstname">
          <FormLabel>Имя</FormLabel>
          <Input
            type="text"
            name="firstname"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
        </FormControl>
        <FormControl id="lastname">
          <FormLabel>Фамилия</FormLabel>
          <Input
            type="text"
            name="lastname"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
        </FormControl>

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Телефон</FormLabel>
          <PhoneInput
            international
            placeholder="Enter phone number"
            defaultCountry="IL"
            value={phone}
            onChange={setPhone}
          />
        </FormControl>
        <HStack>
          <Button
            colorScheme="teal"
            isDisabled={!isUpdated}
            onClick={handleUpdateProfile}
          >
            Обновить
          </Button>
        </HStack>
      </VStack>
    </Box>
  );
};

export default observer(ProfileInfo);
