import React from "react";
import "./loading.scss";
import Container from "../Container/Container";
import { Spinner } from "@chakra-ui/react";

const Loading: React.FC<{ message: string }> = ({ message }) => {
  return (
    <div className="loading">
      <Container>
        <p>Loading {message} ...</p>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="#46507D10"
          color="#46507D"
          size="xl"
          className="loading__spiner"
        />
      </Container>
    </div>
  );
};

export default Loading;
