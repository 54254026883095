import React, { useState, useEffect } from "react";
import UserService from "../../../services/UserService";
import { IUserTour } from "../../../models/IUserTour";
import { Flex } from "@chakra-ui/react";
import TourStatusCard from "../TourStatusCard/TourStatusCard";

const ProfileManage = () => {
  const [allTours, setAllTours] = useState<IUserTour[]>();
  useEffect(() => {
    UserService.getAllBookedTours().then((resp) => setAllTours(resp.data));
  }, []);
  return (
    <Flex wrap="wrap" justifyContent="space-around" gap="32px 16px" my={8}>
      {allTours?.map((userTour) => (
        <TourStatusCard key={userTour._id} tourData={userTour} />
      ))}
    </Flex>
  );
};

export default ProfileManage;
