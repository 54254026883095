import React, { useState, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Text,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { AxiosResponse } from "axios";
import { ICreateTour, ITour } from "../../../hooks/useFetchTours";
import { useNavigate } from "react-router";
import useUpload from "../../../hooks/useUpload";
import useUploadMany from "../../../hooks/useUploadMany";
import ProgressBar from "../../ProgressBar/ProgressBar";

interface TourFormProps {
  createTour: (tourData: ICreateTour) => Promise<AxiosResponse<ITour, any>>;
}

const TourForm: React.FC<TourFormProps> = ({ createTour }) => {
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [xPosition, setXPosition] = useState<string>("");
  const [yPosition, setYPosition] = useState<string>("");

  const [imagesLength, setImagesLength] = useState<number | null>(null);

  const navigate = useNavigate();
  const [isCreatingTour, setIsCreatingTour] = useState(false);

  const {
    progress: progressPreview,
    fileUrl: previewUrl,
    handleUpload,
  } = useUpload();
  const {
    progress: progressImages,
    fileUrls: imageUrls,
    handleUploadMany,
  } = useUploadMany();

  const previewRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsCreatingTour(true);

    const tourData: ICreateTour = {
      title,
      price, 
      xPosition,
      yPosition,
      city,
      country,
      description,
      preview: previewUrl ? previewUrl : "",
      images: imageUrls
    }


    // const formData = new FormData();
    // formData.set("title", title);
    // formData.set("price", String(price));
    // formData.set("xPosition", xPosition);
    // formData.set("yPosition", yPosition);
    // formData.set("city", city);
    // formData.set("country", country);
    // formData.set("description", description);

    // previewUrl && formData.set("preview", previewUrl);
    // imageUrls.forEach((imageUrl, index) => {
    //   formData.append(`images`, imageUrl + "");
    // });

    createTour(tourData)
      .then((resp) => {
        console.log(resp, "resp");
        navigate("/product/" + resp.data._id);
      })
      .finally(() => {
        setIsCreatingTour(false);
      });
  };

  const handlePreviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      handleUpload(file);
    }
  };

  const handleImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(e.target.files, "e.target.files");
      const fileList: FileList = e.target.files;
      setImagesLength(fileList.length);
      const files = Array.from(fileList) as File[];
      handleUploadMany(files);
    }
  };

  return (
    <Box w="100%" maxW="1040" mx="auto">
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Заголовок</FormLabel>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Цена (в долларах)</FormLabel>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Город</FormLabel>
          <Input value={city} onChange={(e) => setCity(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Страна</FormLabel>
          <Input value={country} onChange={(e) => setCountry(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Описание</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <FormControl>
            <FormLabel>Y координата</FormLabel>
            <Input
              placeholder="00.0000"
              value={yPosition}
              onChange={(e) => {
                setYPosition(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>X координата</FormLabel>
            <Input
              placeholder="00.0000"
              value={xPosition}
              onChange={(e) => {
                setXPosition(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        {/* <Map position={[yPosition, xPosition]} /> */}
        <FormControl id="previewImage">
          <FormLabel>Превью картинка</FormLabel>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
            width="350px"
            border="1px dashed gray"
            borderRadius="md"
            cursor="pointer"
            onClick={() => previewRef?.current?.click()}
            // onClick={}
          >
            {!previewUrl && <Text>Upload Image</Text>}

            {previewUrl && (
              <Image
                src={previewUrl}
                alt="Preview Image"
                maxW="350px"
                maxH="300px"
              />
            )}
          </Box>
          <ProgressBar
            minValue={0}
            maxValue={100}
            currentValue={progressPreview}
          />
          <Input
            display="none"
            type="file"
            accept="image/*"
            ref={previewRef}
            onChange={handlePreviewChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Картинки</FormLabel>
          <Input
            type="file"
            multiple
            accept="image/*"
            // ref={previewRef}
            onChange={handleImagesChange}
          />
          <ProgressBar
            minValue={0}
            maxValue={imagesLength === null ? 100 : imagesLength}
            currentValue={progressImages}
          />
          {imageUrls && (
            <Flex my={8} wrap="wrap" gap={4} justifyContent="space-around">
              {imageUrls.map(
                (imageUrl) =>
                  imageUrl && (
                    <Box
                      key={imageUrl}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                      width="250px"
                      border="1px dashed gray"
                      borderRadius="md"
                      cursor="pointer"
                      // onClick={() => previewRef?.current?.click()}
                    >
                      <Image
                        src={imageUrl}
                        alt={`image for ${title}`}
                        maxW="250px"
                        maxH="200px"
                      />
                    </Box>
                  )
              )}
            </Flex>
          )}
        </FormControl>
        <Button
          isLoading={isCreatingTour}
          colorScheme="teal"
          onClick={handleSubmit}
          isDisabled={
            progressPreview !== 100 || progressImages !== imagesLength
          }
        >
          Создать Жилье
        </Button>
      </Stack>
    </Box>
  );
};

export default TourForm;
