import { useEffect, useState } from "react";
import { IUserTour } from "../models/IUserTour";

export interface ITour {
  _id: string;
  title: string;
  price: number;
  xPosition: string;
  yPosition: string;
  city: string;
  country: string;
  description: string;
  preview: string;
  images: string[];
  reviews: IReview[];
  averageRating?: null | number;
  numberOfRatings?: number;
  userTours: IUserTour[];
}

export interface ICreateTour {
  title: string;
  price: number;
  xPosition: string;
  yPosition: string;
  city: string;
  country: string;
  description: string;
  preview: string;
  images: string[];
}

export interface IReview {
  text: string;
  username: string;
  ratingStars: number;
  tourId: string;
}

const useToursLoader = (): [ITour[] | null, boolean, Error | null] => {
  const [tours, setTours] = useState<ITour[] | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const fetchTours = async () => {
      setIsLoading(true);
      try {
        // Выполните запрос к серверу и получите данные
        const response = await fetch(`${process.env.REACT_APP_API_URL}/tour`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        if (response.ok) {
          const responseTours = await response.json();
          setTours(responseTours);
        } else {
          throw new Error("Ошибка при загрузке данных с сервера");
        }
      } catch (err) {
        setError(err as Error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchTours();
  }, []);

  return [tours, isLoading, error];
};

export default useToursLoader;
