import { useRef, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Flex,
  Text,
  Box,
} from "@chakra-ui/react";
import "./reservationModel.scss";

import PhoneInput from "react-phone-number-input";

interface ReservationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (phone: string, email: string) => void;
  startDateInfo: Date;
  endDateInfo: Date;
  totalPrice: number;
  proposedEmail?: string;
  proposedPhone?: string;
}
const ReservationModal: React.FC<ReservationModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  startDateInfo,
  endDateInfo,
  totalPrice,
  proposedEmail,
  proposedPhone,
}) => {
  const [phone, setPhone] = useState<any>();
  const [email, setEmail] = useState<string>("");
  useEffect(() => {
    if (proposedEmail) {
      setEmail(proposedEmail);
    }
    if (proposedPhone) {
      setPhone(proposedPhone);
    }
  }, []);
  const inputRef = useRef();

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleConfirm = () => {
    onConfirm(phone, email);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="scale"
      isCentered={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Забронировать</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Телефон</FormLabel>
            {/* <PhoneInput
              defaultCountry="UA"
              value={phone}
              onChange={setPhone}
              inputComponent={({ value, onChange }) => (
                <Input
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  type="tel"
                  placeholder="Введите номер телефона"
                />
              )}
            />
             */}
            <PhoneInput
              international
              placeholder="Enter phone number"
              defaultCountry="IL"
              value={phone}
              onChange={setPhone}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Email</FormLabel>
            <Input type="email" value={email} onChange={handleEmailChange} />
          </FormControl>
          <Flex align="center" mt={4}>
            <Text fontSize="md" fontWeight="bold">
              Заезд:
            </Text>
            <Box mx={1} />
            <Text fontSize="md">
              {startDateInfo.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </Text>
            <Box mx={2} />
            <Text fontSize="md" fontWeight="bold">
              Выезд:
            </Text>
            <Box mx={1} />
            <Text fontSize="md">
              {endDateInfo.toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "short",
                year: "numeric",
              })}
            </Text>
          </Flex>
          <Flex mt={4}>
            <Text fontSize="lg" fontWeight="bold" mr="2">
              Цена:
            </Text>
            <Text variant="solid">{totalPrice.toFixed(2)}$</Text>
          </Flex>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="teal" onClick={handleConfirm}>
            Подтвердить
          </Button>
          <Button ml={4} onClick={onClose}>
            Отмена
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ReservationModal;
