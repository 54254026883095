import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Stack,
  Text,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import UserService from "../../services/UserService";
import useFetchOneTour from "../../hooks/useFetchOneTour";
import Loading from "../../conmponents/Loading/Loading";
import Notification from "../../conmponents/Notification/Notification";
import ModalDeleteButton from "../../conmponents/ModalDeleteButton/ModalDeleteButton";
import useUpload from "../../hooks/useUpload";
import useUploadMany from "../../hooks/useUploadMany";
import ProgressBar from "../../conmponents/ProgressBar/ProgressBar";
import { ICreateTour } from "../../hooks/useFetchTours";

const EditProduct = () => {
  const { id } = useParams<{ id: string }>();
  const [tour, isLoading, error] = useFetchOneTour(`${id}`);

  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [description, setDescription] = useState("");
  const [xPosition, setXPosition] = useState<string>("");
  const [yPosition, setYPosition] = useState<string>("");

  const [preview, setPreview] = useState("");
  const [images, setImages] = useState<string[]>([]);

  const navigate = useNavigate();
  const [isUpdatingTour, setIsUpdatingTour] = useState(false);

  const previewRef = useRef<HTMLInputElement>(null);

  const [showNotification, setShowNotification] = useState<boolean>(false);
  const [isDeletedNotification, setIsDeletedNotification] =
    useState<boolean>(false);

  const [isTooLargePreview, setIsTooLargePreview] = useState<boolean>(false);
  const [isTooLargeImages, setIsTooLargeImages] = useState<boolean>(false);

  const [imagesLength, setImagesLength] = useState<number | null>(null);

  const {
    progress: progressPreview,
    fileUrl: previewUrl,
    handleUpload,
  } = useUpload();
  const {
    progress: progressImages,
    fileUrls: imageUrls,
    handleUploadMany,
  } = useUploadMany();

  useEffect(() => {
    if (tour) {
      if (tour.title) {
        setTitle(tour.title);
      }
      if (tour.price) {
        setPrice(tour.price);
      }
      if (tour.city) {
        setCity(tour.city);
      }
      if (tour.country) {
        setCountry(tour.country);
      }
      if (tour.description) {
        setDescription(tour.description);
      }
      if (tour.xPosition) {
        setXPosition(tour.xPosition);
      }
      if (tour.yPosition) {
        setYPosition(tour.yPosition);
      }
      if (tour.preview) {
        setPreview(tour.preview);
      }
      if (tour.images) {
        setImages(tour.images);
      }
    }
  }, [tour]);

  useEffect(() => {
    if (previewUrl) {
      setPreview(previewUrl);
    }
  }, [previewUrl]);

  useEffect(() => {
    if (imageUrls.length > 0) {
      const filteredImageUrls = imageUrls.filter(
        (url) => url !== null
      ) as string[];

      setImages(filteredImageUrls);
    }
  }, [imageUrls]);

  if (isLoading) {
    return <Loading message="Apartment" />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!tour) {
    return <div>Tour has not been found</div>;
  }

  const handleCloseNotification = () => {
    setShowNotification(false);
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setIsUpdatingTour(true);

    const tourData: ICreateTour = {
      title,
      price, 
      xPosition,
      yPosition,
      city,
      country,
      description,
      preview: previewUrl ? previewUrl : "",
      images: imageUrls
    }

  //   const formData = new FormData();
  //   formData.set("title", title);
  //   formData.set("price", String(price));
  //   formData.set("xPosition", xPosition);
  //   formData.set("yPosition", yPosition);
  //   formData.set("city", city);
  //   formData.set("country", country);
  //   formData.set("description", description);

  //   previewUrl && formData.set("preview", previewUrl);
  //   imageUrls.forEach((imageUrl, index) => {
  //     formData.append(`images`, imageUrl + "");
  //   });

    UserService.updateTour(tour._id, tourData)
      .then((resp) => {
        console.log(resp, "resp");
        setShowNotification(true);
      })
      .finally(() => setIsUpdatingTour(false));
  };

  const handlePreviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      handleUpload(file);
      // const maxSize = 0.5 * 1024 * 1024;
      // if (file.size <= maxSize) {
      //   const url = URL.createObjectURL(file);
      //   setPreview(url);
      //   setPreviewFile(file);
      //   setPreviewSize(file.size);
      // } else {
      //   setIsTooLargePreview(true);
      //   console.error("Файл слишком большой. Максимальный размер: 0.5 МБ.");
      // }
    }
  };

  const handleImagesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      console.log(e.target.files, "e.target.files");
      const fileList: FileList = e.target.files;
      setImagesLength(fileList.length);
      const files = Array.from(fileList) as File[];
      handleUploadMany(files);

      // let totalFilesSize = 0;
      // files.forEach((file) => (totalFilesSize += file.size));
      // const maxSize = 2.5 * 1024 * 1024;
      // if (totalFilesSize <= maxSize) {
      //   const urls = files.map((file) => URL.createObjectURL(file));

      //   setImages(urls);
      //   setImagesFiles(files);
      //   setImagesSize(totalFilesSize);
      // } else {
      //   setIsTooLargeImages(true);
      //   console.error("Файлы слишком большие. Максимальный размер: 2.5 МБ.");
      // }
    }
  };

  const deleteTour = () => {
    UserService.deleteTour(tour._id).then((resp) => {
      setIsDeletedNotification(true);
      console.log(resp);
    });
  };
  return (
    <Box w="90%" maxW="1040" mx="auto" my={16}>
      {showNotification && (
        <Notification
          message="Успешно обновлено!"
          onClose={handleCloseNotification}
        />
      )}
      {isDeletedNotification && (
        <Notification
          message="Успешно удалено! Перейдите на главную"
          onClose={() => setIsDeletedNotification(false)}
        />
      )}
      {isTooLargePreview && (
        <Notification
          message="Слишком большая картинка! Максимальный размер 0.5 МБ"
          onClose={() => setIsTooLargePreview(false)}
        />
      )}
      {isTooLargeImages && (
        <Notification
          message="Слишком большой размер картинок! Максимальный размер 2.5 МБ"
          onClose={() => setIsTooLargeImages(false)}
        />
      )}
      <Stack spacing={4}>
        <FormControl>
          <FormLabel>Title</FormLabel>
          <Input value={title} onChange={(e) => setTitle(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Price</FormLabel>
          <Input
            type="number"
            value={price}
            onChange={(e) => setPrice(Number(e.target.value))}
          />
        </FormControl>
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input value={city} onChange={(e) => setCity(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Input value={country} onChange={(e) => setCountry(e.target.value)} />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
          <FormControl>
            <FormLabel>Y position</FormLabel>
            <Input
              placeholder="00.0000"
              value={yPosition}
              onChange={(e) => {
                setYPosition(e.target.value);
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>X position</FormLabel>
            <Input
              placeholder="00.0000"
              value={xPosition}
              onChange={(e) => {
                setXPosition(e.target.value);
              }}
            />
          </FormControl>
        </Grid>
        {/* <Map position={[yPosition, xPosition]} /> */}
        <FormControl id="previewImage">
          <FormLabel>Превью</FormLabel>
          {/* <FormLabel>
            Превью (Максимальный размер 0.5 МБ) Загружено{" "}
            {(previewSize / (1024 * 1024)).toFixed(2)} Мб
          </FormLabel> */}
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="300px"
            width="350px"
            border="1px dashed gray"
            borderRadius="md"
            cursor="pointer"
            onClick={() => previewRef?.current?.click()}
            // onClick={}
          >
            {!preview && <Text>Upload Image</Text>}

            {preview && (
              <Image
                src={preview}
                alt="Preview Image"
                maxW="350px"
                maxH="300px"
              />
            )}
          </Box>
          <ProgressBar
            minValue={0}
            maxValue={100}
            currentValue={progressPreview}
          />
          <Input
            display="none"
            type="file"
            accept="image/*"
            ref={previewRef}
            onChange={handlePreviewChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Картинки</FormLabel>
          <Input
            type="file"
            multiple
            accept="image/*"
            // ref={previewRef}
            onChange={handleImagesChange}
          />
          <ProgressBar
            minValue={0}
            maxValue={imagesLength === null ? 100 : imagesLength}
            currentValue={progressImages}
          />
          {images && (
            <Flex my={8} wrap="wrap" gap={4} justifyContent="space-around">
              {images.map(
                (imageUrl) =>
                  imageUrl && (
                    <Box
                      key={imageUrl}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="200px"
                      width="250px"
                      border="1px dashed gray"
                      borderRadius="md"
                      cursor="pointer"
                      // onClick={() => previewRef?.current?.click()}
                    >
                      <Image
                        src={imageUrl}
                        alt={`image for ${title}`}
                        maxW="250px"
                        maxH="200px"
                      />
                    </Box>
                  )
              )}
            </Flex>
          )}
        </FormControl>
        <Button
          isLoading={isUpdatingTour}
          colorScheme="teal"
          onClick={handleSubmit}
          isDisabled={
            (progressPreview > 0 && progressPreview < 100) ||
            (progressImages > 0 && progressImages !== imagesLength)
          }
        >
          Обновить
        </Button>

        <ModalDeleteButton handleDelete={deleteTour} />
      </Stack>
    </Box>
  );
};

export default EditProduct;
