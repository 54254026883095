import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosResponse } from "axios";

type UsePostRequestHook<T, G> = [
  response: T | null,
  isLoading: boolean,
  error: AxiosError<T> | null,
  sendPostRequest: (url: string, data: G) => Promise<void>
];

function usePostRequest<T, G>(): UsePostRequestHook<T, G> {
  const [response, setResponse] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<AxiosError<T> | null>(null);

  const sendPostRequest = async (url: string, data: G) => {
    setIsLoading(true);
    try {
      const axiosResponse: AxiosResponse<T> = await axios.post(url, data, {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      });
      setResponse(axiosResponse.data);
      setError(null);
    } catch (err) {
      setError(err as AxiosError<T>);
    } finally {
      setIsLoading(false);
    }
  };

  return [response, isLoading, error, sendPostRequest];
}

export default usePostRequest;
