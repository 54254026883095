import { useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Textarea,
} from "@chakra-ui/react";
import RatingStars from "react-rating-stars-component";
import usePostRequest from "../../../hooks/usePostRequest";
import { IReview } from "../../../hooks/useFetchTours";
import Loading from "../../Loading/Loading";
import Error from "../../Error/Error";

interface ReviewFormProps {
  // onSubmit: (reviewText: string, rating: number) => void;
  tourId: string;
}

const ReviewForm: React.FC<ReviewFormProps> = ({ tourId }) => {
  const [reviewText, setReviewText] = useState("");
  const [rating, setRating] = useState(0);

  const [response, isLoading, error, sendPostRequest] = usePostRequest<
    IReview,
    IReview
  >();

  const createReview = (e: React.FormEvent) => {
    e.preventDefault();
    sendPostRequest(`${process.env.REACT_APP_API_URL}/tour/review`, {
      text: reviewText,
      tourId,
      username: "Sebastian",
      ratingStars: rating,
    }).then(() => {
      window.location.reload();
      console.log(response, "response");
    });
  };

  if (isLoading) {
    return <Loading message="Loading review form" />;
  }

  if (error) {
    return <Error errorMessage={error.message} />;
  }

  return (
    <Box maxW="1040" mx="auto">
      <form onSubmit={createReview}>
        <Stack spacing="4">
          <FormControl id="rating" isRequired>
            <FormLabel>Rating</FormLabel>
            <RatingStars
              count={5}
              onChange={(newRating: number) => setRating(newRating)}
              size={24}
              activeColor="#ffd700"
            />
          </FormControl>
          <FormControl id="reviewText" isRequired>
            <FormLabel>Review Text (max 400 symbols)</FormLabel>
            <Textarea
              maxLength={400}
              maxHeight={250}
              height={95}
              value={reviewText}
              onChange={(e) => setReviewText(e.target.value)}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            isDisabled={!rating || !reviewText}
          >
            Submit
          </Button>
        </Stack>
      </form>
    </Box>
  );
};

export default ReviewForm;
