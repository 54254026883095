import React, { useState, useEffect } from "react";
import {
  Box,
  Center,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  VStack,
} from "@chakra-ui/react";
import ProgressBar from "../../conmponents/ProgressBar/ProgressBar";
import PhoneInput from "react-phone-number-input";
import UserService from "../../services/UserService";
import { useParams } from "react-router";
import { IUser } from "../../models/IUser";

const User = () => {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<any>();
  const [profilePhoto, setProfilePhoto] = useState<string>("");

  const { id } = useParams<{ id: string }>();
  const [user, setUser] = useState<IUser>({} as IUser);

  useEffect(() => {
    UserService.fetchOneUser(`${id}`).then((resp) => {
      console.log(resp);
      setUser(resp.data);
    });
  }, []);

  useEffect(() => {
    if (user?.firstname) {
      setFirstname(user?.firstname);
    }
    if (user?.lastname) {
      setLastname(user?.lastname);
    }
    if (user?.email) {
      setEmail(user?.email);
    }
    if (user?.phone) {
      setPhone(user?.phone);
    }
    if (user?.profilePhoto) {
      setProfilePhoto(user?.profilePhoto);
    }
  }, [user]);

  return (
    <Box p={4} maxW={750} mx="auto">
      <Center>
        <Heading as="h2" my={8}>
          Профиль пользователя
        </Heading>
      </Center>
      <VStack spacing={4} align="start">
        <FormControl id="profileImage">
          <FormLabel>Картинка профиля</FormLabel>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="200px"
            width="200px"
            border="1px dashed gray"
            borderRadius="md"
            cursor="pointer"
          >
            {profilePhoto && (
              <Image
                src={profilePhoto}
                alt="Profile Image"
                maxW="200px"
                maxH="200px"
              />
            )}
          </Box>
        </FormControl>
        <FormControl id="firstname">
          <FormLabel>Имя</FormLabel>
          <Input type="text" name="firstname" value={firstname} readOnly />
        </FormControl>
        <FormControl id="lastname">
          <FormLabel>Фамилия</FormLabel>
          <Input type="text" name="lastname" value={lastname} readOnly />
        </FormControl>

        <FormControl id="email">
          <FormLabel>Email</FormLabel>
          <Input type="email" name="email" value={email} readOnly />
        </FormControl>
        <FormControl>
          <FormLabel>Телефон</FormLabel>
          <PhoneInput
            international
            placeholder="Enter phone number"
            defaultCountry="IL"
            value={phone}
            onChange={setPhone}
            readOnly
          />
        </FormControl>
      </VStack>
    </Box>
  );
};

export default User;
