import { AxiosResponse } from "axios";
import $api from "../http";
import { AuthResponse } from "../models/response/AuthResponse";



export default class AuthService {
  static async login(email: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/login', { email, password })
  }

  static async register(email: string, phone: string, password: string): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>('/auth/register', { email, phone, password })
  }
  
  static async checkAuth(): Promise<AxiosResponse<AuthResponse>> {
    return $api.get('auth/checkAuth')
  }
}