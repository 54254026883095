import { LatLngExpression, Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./map.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { Text } from "@chakra-ui/react";
import LeafletControlGeocoder from "./LeafletControlGeocoder/LeafletControlGeocoder";

const Map: React.FC<{ position: [string, string] }> = ({ position }) => {
  const customIcon = new Icon({
    iconUrl: "https://www.svgrepo.com/show/302636/map-marker.svg", // Замените на путь к вашей кастомной иконке
    iconSize: [32, 32], // Размер иконки в пикселях
    iconAnchor: [16, 32],
  });

  if (
    position[0] &&
    position[1] &&
    !isNaN(+position[0]) &&
    !isNaN(+position[1])
  ) {
    const resPos = [+position[0], +position[1]] as LatLngExpression;
    return (
      <MapContainer center={resPos} zoom={14} scrollWheelZoom={false}>
        <LeafletControlGeocoder />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <Marker position={resPos} icon={customIcon}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    );
  } else {
    return <></>;
  }
};

export default Map;
