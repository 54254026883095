import React from "react";
import { IReview } from "../../../hooks/useFetchTours";
import { Box, Flex, Text, VStack } from "@chakra-ui/react";
import RatingStars from "react-rating-stars-component";

interface ReviewProps {
  review: IReview;
}

const Review: React.FC<ReviewProps> = ({ review }) => {
  return (
    <Box borderWidth="1px" borderRadius="md" p="4" my="4">
      <VStack spacing="2" alignItems="flex-start">
        <Flex
          direction="row"
          justifyContent="space-between"
          w="100%"
          alignItems="center"
        >
          <Text fontSize="lg" fontWeight="bold">
            {review.username}
          </Text>
          <RatingStars
            value={review.ratingStars ? review.ratingStars : 0}
            count={5}
            size={24}
            activeColor="#ffd700"
            edit={false}
          />
        </Flex>
        <Text>{review.text}</Text>
      </VStack>
    </Box>
  );
};

export default Review;
