import { AxiosResponse } from "axios";
import $api from "../http";
import { IAuthUser } from "../models/IAuthUser";
import { IUserTour } from "../models/IUserTour";
import { IBookTour } from "./interfaces/IBookTour";
import { IUser } from "../models/IUser";
import { ICreateTour, ITour } from "../hooks/useFetchTours";
import { IUpdateUser } from "./interfaces/IUpdateUser";

export default class UserService {
  static async fetchOneUser(id: string): Promise<AxiosResponse<IUser>> {
    return $api.get<IUser>('/users/' + id)
  }

  static async fetchUsers(): Promise<AxiosResponse<IUser[]>> {
    return $api.get<IUser[]>('/users')
  }

  static async updateUser(userData: IUpdateUser): Promise<AxiosResponse<IAuthUser>> {
    return $api.put<IAuthUser>('/users', userData)
  }

  static async createTour(tourData: ICreateTour): Promise<AxiosResponse<ITour>> {
    try {
      const response = await $api.post<ITour>('/tour', tourData)
      return response
    } catch (err) {
      console.log(err, 'consoling error');
      throw err;
    }
  }

  static async updateTour(tourId: string, tourData: ICreateTour): Promise<AxiosResponse<ITour>> {
    try {
      const response = await (await $api.put<ITour>('/tour/' + tourId, tourData))
      return response
    } catch (err) {
      console.log(err, 'consoling error');
      throw err;
    }
  }

  static async changeTourStatus(userTourId: string, status: "sentRequest"
    | "confirmed"
    | "living"
    | "ended"
    | "error"): Promise<AxiosResponse<IUserTour>> {
    return $api.put('/users/booked/status', { userTourId, status })
  }


  static async bookTour(bookTourDto: IBookTour): Promise<AxiosResponse<IUserTour>> {
    return $api.post<IUserTour>('/users/book', bookTourDto)
  }

  static async deleteUserTour(userTourId: string): Promise<AxiosResponse<string>> {
    return $api.delete<string>('/users/booked/' + userTourId)
  }

  static async getAllBookedTours(): Promise<AxiosResponse<IUserTour[]>> {
    return $api.get('/users/booked');
  }

  static async deleteTour(id: string): Promise<AxiosResponse<string>> {
    return $api.delete('/tour/' + id)
  }

  static async sendEmailNotification(senderEmail: string, message: string): Promise<AxiosResponse<void>> {
    return $api.post('/users/sendEmail', { senderEmail, message })
  }
}