import React from "react";

import { Link } from "react-router-dom";
import { ITour } from "../../hooks/useFetchTours";
import "./productsList.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import RatingStars from "react-rating-stars-component";
import { Flex } from "@chakra-ui/react";

interface ProductItemProps {
  tour: ITour;
}

const ProductItem: React.FC<ProductItemProps> = ({ tour }) => {
  return (
    <Link
      to={`product/${tour._id}`}
      className="productItem tracking-in-expand-fwd-bottom"
    >
      <div className="productItem__top">
        {tour.preview ? (
          <img
            src={tour.preview}
            alt={tour.title}
          />
        ) : (
          <img
            src={`https://lasd.lv/public/assets/no-image.png`}
            alt={tour.title}
          />
        )}
      </div>
      <div className="productItem__bottom">
        <div className="productItem__location">
          <FontAwesomeIcon
            icon={faGlobe}
            style={{ color: "#252f41" }}
            fontSize={12}
          />
          <p className="productItem__location-text">
            {tour.city}, {tour.country}
          </p>
        </div>
        <p className="productItem__title">{tour.title}</p>
        <Flex gap={2}> 
          <RatingStars
            value={tour.averageRating ? Math.round(tour.averageRating) : 0}
            count={5}
            size={24}
            activeColor="#ffd700"
            edit={false}
          />{" "}
          <p>({tour.numberOfRatings})</p>
        </Flex>
        {/* <p className="productItem__description">{tour.description}</p> */}
        <div className="productItem__price">
          <p className="productItem__price-text">from</p>
          <p className="productItem__price-number">{tour.price}$</p>
        </div>
      </div>
      {/* <span className="productItem__label">Read more</span> */}
    </Link>
  );
};

export default ProductItem;
