import { makeAutoObservable } from "mobx";
import AuthService from "../services/AuthService";
import { IAuthUser } from "../models/IAuthUser";
import axios from "axios";
import { AuthResponse } from "../models/response/AuthResponse";
import UserService from "../services/UserService";
import { IUpdateUser } from "../services/interfaces/IUpdateUser";

export default class Store {
  user = {} as IAuthUser
  isAuth = false
  isLoading = false

  constructor() {
    makeAutoObservable(this);
    this.updateUser = this.updateUser.bind(this);
  }

  setAuth(isAuth: boolean) {
    this.isAuth = isAuth
  }
  setUser(user: IAuthUser) {
    this.user = user
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading
  }

  async login(email: string, password: string) {
    try {
      const response = await AuthService.login(email, password)
      console.log('response login ', response)
      localStorage.setItem('token', response.data.token)
      this.setAuth(true);
      this.setUser(response.data.user)
      return response.status;
    } catch (err: any) {
      alert(err.message)
      return err.response.status
    }
  }

  async register(email: string, phone: string, password: string) {
    try {
      const response = await AuthService.register(email, phone, password)
      localStorage.setItem('token', response.data.token)
      this.setAuth(true);
      this.setUser(response.data.user)
    } catch (err: any) {
      console.log(err?.response?.data?.message)
    }
  }

  async logout() {
    try {
      localStorage.removeItem('token')
      this.setAuth(false);
      this.setUser({} as IAuthUser)
    } catch (err: any) {
      console.log(err?.response?.data?.message)
    }
  }

  async checkAuth() {
    this.setLoading(true)
    try {
      const response = await AuthService.checkAuth();
      this.setAuth(true);
      this.setUser(response.data.user);
    } catch (err: any) {
      console.log(err?.response?.data?.message)
    } finally {
      this.setLoading(false)
    }
  }

  async updateUser(userData: IUpdateUser) {
    this.setLoading(true);
    try {
      const response = await UserService.updateUser(userData)
    } catch (err: any) {
      console.log(err?.response?.data?.message)
    } finally {
      this.setLoading(false)
    }
  }
}