import { useState, useEffect } from "react";
import { Box, Progress } from "@chakra-ui/react";
import "./progressBar.scss";

interface ProgressBarProps {
  minValue: number;
  maxValue: number;
  currentValue: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  minValue,
  maxValue,
  currentValue,
}) => {
  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const calculatedProgress =
      ((currentValue - minValue) / (maxValue - minValue)) * 100;
    setProgressValue(calculatedProgress);
  }, [currentValue, minValue, maxValue]);

  return (
    <Box
      pos="relative"
      borderRadius="lg"
      maxWidth={350}
      my={4}
      overflow="hidden"
    >
      <Progress
        value={progressValue}
        size="md"
        colorScheme="blue"
        borderRadius="lg"
        h={8}
        maxWidth={350}
        overflow="hidden"
      >
        <Box
          pos="absolute"
          top={0}
          left={0}
          h="100%"
          bgGradient={
            progressValue !== 0 && progressValue !== 100
              ? "linear(to right,  transparent 0%,  rgba(255, 255, 255, 0.5) 10%,  transparent 40%)"
              : ""
          }
          w="100%"
          className={
            progressValue !== 0 && progressValue !== 100
              ? "animated-gradient"
              : ""
          }
        />
      </Progress>
    </Box>
  );
};

export default ProgressBar;
