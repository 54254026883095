import React from "react";
import Container from "../Container/Container";

import "./about.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTiktok,
  faAirbnb,
  // faBooking,
} from "@fortawesome/free-brands-svg-icons";
import {
  faB,
  faBed,
  faEnvelope,
  faHotel,
  faTachometerAlt,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import EmailForm from "../EmailForm/EmailForm";

const About = () => {
  return (
    <div className="about">
      <Container>
        <h2 className="about__title">Про нас</h2>
        <div className="about__wrapper">
          <div className="about__content">
            <p>
              {" "}
              Добро пожаловать в Oasis – ваш источник посуточного жилья в
              Израиле!
            </p>

            <p>
              Мы – молодая и креативная команда, которая предлагает широкий
              выбор квартир для аренды. Наша основная цель – обеспечить ваш
              комфорт и удовлетворение во время пребывания в Израиле.
            </p>

            <p>
              Мы понимаем, что каждый клиент уникален, поэтому мы постоянно
              работаем над расширением нашей базы предложений, чтобы
              предоставить вам разнообразие вариантов жилья. Независимо от того,
              ищете ли вы роскошный апартамент, уютную студию или просторный
              дом, у нас есть идеальное жилье для вас.
            </p>

            <p>
              Мы придаем большое значение вашему опыту аренды. Наша команда
              предоставит вам профессиональную поддержку на каждом этапе
              процесса аренды. Мы стремимся сделать этот процесс максимально
              удобным, чтобы вы могли сосредоточиться на наслаждении вашего
              пребывания.
            </p>

            <p>
              Оставьте все заботы о поиске жилья на нас. Позвольте нам стать
              вашим надежным партнером по аренде жилья в Израиле. Приходите в
              Oasis, и откройте для себя оазис комфорта и удовольствия в вашем
              временном доме.
            </p>

            <p>Добро пожаловать в Oasis – ваш дом вдали от дома!</p>
          </div>
          <div className="about__icons-list">
            <a
              href="https://www.facebook.com/profile.php?id=100093326982358&mibextid=LQQJ4d"
              className="about__icons-item"
            >
              <FontAwesomeIcon icon={faFacebook} className="about__icon" />
              <p className="about__icons-text">Facebook</p>
            </a>

            <a
              href="https://instagram.com/oasis__easy_with_us?igshid=OGQ5ZDc2ODk2ZA=="
              className="about__icons-item"
            >
              <FontAwesomeIcon icon={faInstagram} className="about__icon" />
              <p className="about__icons-text">Instagram</p>
            </a>

            <a
              href="https://www.tiktok.com/@oasis_real_estate?_t=8e5szFWhPEo&_r=1"
              className="about__icons-item"
            >
              <FontAwesomeIcon icon={faTiktok} className="about__icon" />
              <p className="about__icons-text">Tiktok</p>
            </a>
            <a
              href="mailto:oasisitseasywithus@gmail.com"
              className="about__icons-item"
            >
              <FontAwesomeIcon icon={faEnvelope} className="about__icon" />
              <p className="about__icons-text">Gmail</p>
            </a>
            {/* <a href="#" className="about__icons-item">
              <FontAwesomeIcon icon={faAirbnb} className="about__icon" />
              <p className="about__icons-text">airbnb@oasis.com</p>
            </a>
            <a href="#" className="about__icons-item">
              <FontAwesomeIcon icon={faBed} className="about__icon" />
              <p className="about__icons-text">booking@oasis.com</p>
            </a> */}
          </div>
        </div>

        <EmailForm />
      </Container>
    </div>
  );
};

export default About;
