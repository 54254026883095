import React from "react";
import Header from "../../conmponents/Header/Header";
import About from "../../conmponents/About/About";
import ProductsList from "../../conmponents/ProductsList/ProductsList";
import Picture from "../../conmponents/Picture/Picture";
import Advantages from "../../conmponents/Advantages/Advantages";
import { observer } from "mobx-react-lite";

const Home = () => {
  return (
    <div>
      <Picture />
      <Advantages />
      <ProductsList />
      <About />
    </div>
  );
};

export default observer(Home);
