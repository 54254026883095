import { useState } from "react";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import storage from "../firebase-config";

const useUploadMany = (): {
  progress: number;
  fileUrls: (string)[];
  handleUploadMany: (files: File[]) => Promise<string[]>;
} => {
  const [progress, setProgress] = useState<number>(0);
  const [fileUrls, setFileUrls] = useState<string[]>([]);

  const handleUploadMany = async (files: File[]): Promise<string[]> => {
    setProgress(0);
    setFileUrls([]);
    files.forEach(async file => {
      const storageRef = ref(storage, `/files/${file.name}`);
      const snapshot = await uploadBytesResumable(storageRef, file);

      const downloadUrl: string = await getDownloadURL(snapshot.ref);
      setFileUrls(prev => [...prev, downloadUrl]);
      setProgress(prev => prev + 1)
    })

    return fileUrls
  }
  return { progress, fileUrls, handleUploadMany };
};

export default useUploadMany;