import { Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";

const LoadingSpinner = () => {
  return (
    <Flex
      align="center"
      justify="center"
      position="fixed"
      top={0}
      left={0}
      width="100%"
      height="100%"
      backgroundColor="#46507D"
      zIndex={9999}
      gap={8}
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="#46507D10"
        color="white"
        size="xl"
        className="loading__spiner"
      />
      <Text color={"white"} fontSize={40}>
        Loading...
      </Text>
    </Flex>
  );
};

export default LoadingSpinner;
