import {
  Tabs,
  Tab,
  TabList,
  TabIndicator,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import React, { ElementType } from "react";

export interface ITabData {
  label: string;
  component: React.ReactNode;
}

const TabsComponent: React.FC<{ tabsData: ITabData[] }> = ({ tabsData }) => {
  return (
    <Tabs position="relative" variant="unstyled">
      <TabList>
        {tabsData.map((tab) => (
          <Tab key={tab.label}>{tab.label}</Tab>
        ))}
      </TabList>
      <TabIndicator mt="-1.5px" height="2px" bg="blue.500" borderRadius="1px" />
      <TabPanels>
        {tabsData.map((tab) => (
          <TabPanel key={tab.label + "-component"}>{tab.component}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export default TabsComponent;
