
import About from "../conmponents/About/About";
import Profile from "../conmponents/Profile/Profile";
import Register from "../conmponents/Register/Register";
import EditProduct from "../pages/EditProduct/EditProduct";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import Product from "../pages/Product/Product";
import User from "../pages/User/User";

export interface IRoute {
  path: string;
  component: React.ComponentType | React.FC<any>;
  exact?: boolean;
}

export enum routeNames {
  LOGIN = "/login",
  REGISTER = "/register",
  HOME = "/",
  PRODUCT = "/product/:id",
  EDIT_PRODUCT = "/product/edit/:id",
  PROFILE = '/profile',
  USER = "/user/:id"
}

export const publicRoutes: IRoute[] = [
  {
    path: routeNames.LOGIN,
    component: Login,
    exact: true,
  },
  {
    path: routeNames.REGISTER,
    component: Register,
    exact: true,
  },
  {
    path: routeNames.HOME,
    component: Home,
    exact: true,
  },
  {
    path: routeNames.PRODUCT,
    component: Product,
    exact: true,
  }
];

export const privateRoutes: IRoute[] = [
  {
    path: routeNames.PROFILE,
    component: Profile,
    exact: false
  },
  {
    path: routeNames.HOME,
    component: Home,
    exact: true,
  },
  {
    path: routeNames.PRODUCT,
    component: Product,
    exact: true,
  },
  {
    path: routeNames.EDIT_PRODUCT,
    component: EditProduct,
    exact: true,
  }
];

export const adminRoutes: IRoute[] = [
  {
    path: routeNames.USER,
    component: User,
    exact: true
  }
]